import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { StaticImage } from 'gatsby-plugin-image';

const Modal = ({ open, handleSetOpen }) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="modal" onClose={() => handleSetOpen(false)}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="modal-panel-wrapper">
        <div className="p-4 text-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="bg-black rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl lg:max-w-3xl xl:max-w-5xl sm:w-full">
              <div className="modal-button-wrapper bg-transparent pt-2 pr-2 sm:pt-4 sm:pr-4">
                <button
                  type="button"
                  className="bg-transparent sm:bg-transparent text-black sm:text-white focus:outline-none focus:ring-2 focus:ring-offset-0 sm:focus:ring-offset-0 focus:ring-gray-500 sm:focus:ring-gray-500"
                  onClick={() => handleSetOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg className="h-12 w-12 sm:w-10 sm:h-10 bg-transparent" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="modal-content-wrapper">
                <div className="sm:max-w-sm sm:min-h-[400px] lg:min-h-[500px] xl:min-h-[550px]">
                  <StaticImage
                    src="../images/Clara-glamour-photo-touch-up-960x1000.jpg"
                    alt="Clara Ponty"
                    objectFit="cover"
                    loading="eager"
                    placeholder="none"
                    className="h-full"
                  />
                </div>
                <div className="modal-content px-4 py-8 sm:py-12 sm:px-8 sm:pb-24 text-center sm:text-left">
                  <Dialog.Title as="h2" className="mt-0 text-gray-100 font-normal text-2xl">
                    Clara Ponty
                  </Dialog.Title>
                  <h3 className="text-base text-gray-100 mt-4 font-normal">
                    Musical Imagination
                  </h3>
                  <div className="mt-2">
                    <p className="text-gray-100 font-italic hidden sm:block text-sm">
                      “Music is,” Sidney Lanier writes, “Love in search of a word.” Imagination is a wondrous vehicle of this search, which invites us to breathe, to dream, and to be fully present to the wonder of living. Joined together, the musical imagination is revealed ...
                    </p>
                    <p className="text-gray-100 lg:mt-12 text-sm">
                      “I'm delighted to be a part of the new issue of IMAGINE Magazine with my essay, <em>Musical Imagination</em>.”
                      <br />
                      — Clara Ponty
                    </p>
                    <p className="sm:hidden text-gray-100 text-sm">
                      <a href="https://imaginezine.com/clara-ponty" className="decoration-razzzmatazz" rel="nofollow noreferrer">Read the essay</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-transparent pb-2 pr-4">
                <p className="hidden sm:block text-gray-100 text-sm pr-4 pb-8">
                  <a href="https://imaginezine.com/clara-ponty" className="decoration-razzzmatazz" rel="nofollow noreferrer">Read the essay</a>
                </p>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

export default Modal;
